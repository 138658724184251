import { useNavigate } from "react-router-dom";
import styles from './banner.module.css';

function Banner() {
    const navigate = useNavigate();
    return (
        <div id={styles.banner} onClick={() => { navigate('contact') }}>
            APPLY FOR A FREE DISCOVERY CALL.
        </div>
    );
}

export default Banner;