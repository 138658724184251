import { useRef } from 'react';
import Banner from './components/banner/banner.js';
import Nav from './components/nav/nav.js';
import Meet from './components/sections/meet/meet.js';
import About from './components/sections/about/about.js';
import Contact from './components/pages/contact/contact.js';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import './common.css'

function App() {
  const aboutRef = useRef(null); // Reference to the About section
  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
      console.log('hit')
    }
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={
          <div className="container">
            <Banner />
            <Nav scroll={scrollToAbout} />
            <Meet />
            <div ref={aboutRef}>
              <About />
            </div>
          </div>
        } />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
