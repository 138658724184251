import Button from '../../button/button.js';

import styles from './meet.module.css';
import portrait from '../../../media/images/portrait.png';

function Meet() {
    return (
        <div id={styles["container"]}>
            <div id={styles["left"]}>
                <img src={portrait} />
            </div>
            <div id={styles["right"]}>
                <h1 id={styles["title"]}>
                Nutrition + a sincere <i>‘Niya’</i> (intention) = <br/><span className={styles["font-text"]}>N I Y A T R I T I O N</span>
                </h1>
                <div id={styles["body"]}>
                    <p>I’m Suzan, your Nutritional Therapist.  I take a root-cause approach to attaining wellness and help clients all over the world take charge of their health and nourish from within using my Niyatrition approach.</p>
                    <p>Do you feel like your body is working against you?</p>
                    <p>Maybe you’ve been carrying years of extra weight around or are silently struggling with chronic discomfort, pain, daily fatigue, brain fog, bloating, diarrhea/constipation, acid reflux or headaches. </p>
                    <p>Like many of my clients you might be feeling hopeless because you’ve followed your doctor’s advice of eating less and moving more but the scale isn’t budging and neither is your high blood sugar, high blood pressure or high cholesterol.</p>
                    <p>Sometimes, when we’ve been sick for so long, we forget what it feels like to be well and accept it as our new fate. </p>
                    <p>It doesn’t have to be this way my friend. </p>
                    <p>Wanna know a secret? It’s not your fault. Really.</p>
                    <p>You just haven’t uncovered the root causes of your unwellness yet. </p>
                    <p>I know, because I’ve been there.</p>
                    <div>
                        <Button onClick={()=>{}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Meet;