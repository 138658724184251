import { useNavigate } from "react-router-dom";
import styles from './nav.module.css';

import logo from '../../media/images/logo.png';

function Nav(props) {
    const navigate = useNavigate();
    return (
        <div id={styles["container"]}>
            <div id={styles["left"]}>
                <img id={styles["logo"]} src={logo} />
            </div>
            <div id={styles["center"]}>
                <a>HOME</a>
                <a onClick={()=>{props.scroll()}}>ABOUT</a>
                <a>WORK WITH ME</a>:
            </div>
            <div id={styles["right"]}>
                <div id={styles["contact-container"]}>
                    <p onClick={() => { navigate('contact') }}>CONTACT</p>
                    <div id={styles["line"]} />
                </div>
            </div>
        </div>
    );
}

export default Nav;
