import { useNavigate } from "react-router-dom";

import styles from './button.module.css';

function Button() {
    const navigate = useNavigate();

    return (
        <div id={styles["button"]} onClick={() => { navigate('contact') }}>
            WORK WITH SUZAN
        </div>
    );
}

export default Button;
