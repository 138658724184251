import Button from '../../button/button.js';
import styles from './about.module.css';

import one from '../../../media/images/about/1.png';
import two from '../../../media/images/about/2.png';
import three from '../../../media/images/about/3.png';
import four from '../../../media/images/about/4.png';
import five from '../../../media/images/about/5.png';
import six from '../../../media/images/about/6.png';
import seven from '../../../media/images/about/7.png';
import eight from '../../../media/images/about/8.png';

function About() {
    return (
        <div id={styles["container"]}>
            <div id={styles["left"]}>
                <div id={styles["first"]}>
                    <div className={styles["grid-item"]}>
                        <img src={four} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={three} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={five} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={one} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={seven} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={two} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={six} />
                    </div>
                    <div className={styles["grid-item"]}>
                        <img src={eight} />
                    </div>
                </div>
            </div>
            <div id={styles["right"]}>
                <div id={styles["text-container"]}>
                    <h1 id={styles["get-to-know-title"]}>Get To Know <span className={styles["cursive"]}>Suzan</span></h1>
                    <div className={styles["section"]}>
                        <h3 className={styles["title"]}>How I Got Here...</h3>
                        <p>Helping people has always been my passion…</p>
                        <p>Whether it was my role as mom, wife, high school biology teacher, assistant dean in a university, research molecular biologist, volunteer, professional baker or Nutritional Therapist… connecting with and helping others brings true joy to my heart. </p>
                        <p>I think the biggest reason I’m successful in helping my clients take charge of their health and swap their mindset from managing disease to <b>maintaining wellness</b> is because my story is one they can very much relate to. I’ve been exactly where they are.</p>
                        <p>My high school nickname sums up my eating habits growing up: ‘Cookie Monster!’ I grew up in America's Midwest, was very athletic and never struggled with my weight, despite being a sugar and carb addict. One day, as it usually does, it all caught up with me. After 3 pregnancies and spending years on the dieting rollercoaster, I found myself 50 pounds overweight and with high cholesterol.</p>
                        <p>My doctor wanted to put me on statins but I was only 39 and knew there had to be a better, more natural way. I followed my doctor’s advice to eat less, move more and avoid saturated fats. Then I got my first health coach certification in 2011. Unfortunately, most nutrition programs at that time still advocated the misguided low-fat, more whole grains approach which did nothing to improve my lipid panel or my waistline.</p>
                    </div>
                    <div className={styles["section"]}>
                        <h3 className={styles["title"]}>My Aha Moment</h3>
                        <p>In 2012 I was introduced to a nutrient dense, whole foods way of eating by some very dear friends. I dove in because I was determined to heal my body, have more energy and set a good example for my kids. I also wanted to fit into my size 8 jeans again.
                            <br /><b>The results?</b> A massive and super fast transformation to my health, alhamdulilah!
                            I finally broke my sugar and starch addiction and it was so LIBERATING!  Most importantly, in just three months my cholesterol numbers were back in the normal range and, at 40, I looked and felt like I did in my 20’s masha’ALLAH!
                            <br /><b>The bonus?</b> For the first time in 13 years, I reached my weight loss goal. I lost 45 pounds and went from a bursting size 14 to a comfortable size 6. It was like a switch turned on inside, causing me to easily burn fat, gain energy, increase focus, and even sleep better. </p>
                    </div>
                    <div className={styles["section"]}>
                        <h3 className={styles["title"]}>The Power Of Food As Medicine!</h3>
                        <p>This was the moment I knew I had to educate and empower others to do the same. So, I left my teaching job to pursue further nutritional certifications and do this full time.</p>
                    </div>
                    <div className={styles["section"]}>
                        <h3 className={styles["title"]}>Autoimmune Disease and Healing My Gut</h3>
                        <p>One of those certifications was becoming an Autoimmune Health Coach and a year after completing this certification I was diagnosed with an autoimmune skin condition.</p>
                        <p>I was just the right person to help myself! It was a long road, but a combination of the AIP (AutoImmune Paleo) protocol, healing my gut, better managing my stress (and lots of prayer) that subdued my autoimmune disease.</p>
                    </div>
                    <div className={styles["section"]}>
                        <h3 className={styles["title"]}>Welcome To The Road To Wellness. </h3>
                        <p>You’re in the right place. Helping my clients all around the world to optimize their health by harnessing the power of real food brings me PURE JOY. I am so honored to be here as a part of your journey too. I would love to help you get to the root cause of your unwellness and weight gain, and to help you feel better than you ever have before insha’ALLAH!</p>
                    </div>
                </div>
                <Button></Button>
            </div>
        </div>
    );
}

export default About;