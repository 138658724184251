import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

import styles from './contact.module.css';

import logo from '../../../media/images/logo.png';

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_fr061ri', 'template_xtbve53', form.current, {
                publicKey: '7faCkCrXUAQ4LT7xD',
            })
            .then(
                () => {
                    toast.success("Message sent!");
                    console.log('SUCCESS!');
                },
                (error) => {
                    toast.error("Could not send message. Please try again.");
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div id={styles["container"]}>
            <div id={styles["window"]}>
                <img id={styles["logo"]} src={logo} />
                <form ref={form} onSubmit={sendEmail}>
                    <div className={styles["input-item"]}>
                        <label>First Name</label>
                        <input type="text" name="first_name" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>Last Name</label>
                        <input type="text" name="last_name" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>Email</label>
                        <input type="text" name="email" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>Phone Number</label>
                        <input type="text" name="phone" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>State/Region</label>
                        <input type="text" name="state_region" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>How would you like to work with Suzan? <span style={{fontSize: '11px'}}>(One-on-one nutritional counseling, group program, Wellness Workshop, Guest Speaker)</span></label>
                        <textarea name="how_work" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>List, in order of importance, your top 3 health goals.</label>
                        <textarea name="health_goals" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>How would your life be different if these were no longer a concern?</label>
                        <textarea name="life_different" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>What have you tried in the past? (diets, medication, etc)</label>
                        <textarea name="things_tried" />
                    </div>
                    <div className={styles["input-item"]}>
                        <label>What has been getting in the way of your success in attaining your health goals?</label>
                        <textarea name="burdens_of_success" />
                    </div>
                    <input type="submit" value="Submit" />
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

// First name*
// Last name*
// Email*
// Phone number 
// State/Region*
// How would you like to work with Suzan? *
// One-on-one nutritional counseling, group program, Wellness Workshop, Guest Speaker
// List, in order of importance, your top 3 health goals.
// How would your life be different if these were no longer a concern?
// What have you tried in the past? (diets, medication, etc)
// What has been getting in the way of your success in attaining your health goals? 


export default Contact;